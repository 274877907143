var LOCATION_MAX_AGE = 10 * 60 * 1000; // 10min

var URL_START_FILENAME = "UrlStart.json";

function UrlHandlerExtension() {
    Debug.UrlHandler && console.info("UrlHandlerExtension ctor");
    PersistenceComponent.getLoadingPromise().then(() => {
        // prepare current location
        try {
            var locationObj = JSON.parse(PersistenceComponent.getLocalStorageItem("location"));
        } catch (e) {// don't do anything
        }

        var loc = "";

        if (locationObj !== null && typeof locationObj === "object") {
            //var diff = Date.now() - locationObj.date;     // since we can now choose default entry points, we don't need to invalidate, because it should always be the last location!
            //if (diff < LOCATION_MAX_AGE) {
            loc = locationObj.location; //}
        }

        this.setEntryPointURL(loc || "");

        window.handleOpenURL = function handleOpenURL(url) {
            Debug.UrlHandler && console.info("UrlHandlerExt: handleOpenURL:" + url);
            return this.urlStart(url);
        }.bind(this);

        if (window._cachedUrlStart) {
            this._handleCachedUrlStart();
        }
    });
}

UrlHandlerExtension.prototype.setEntryPointURL = function setEntryPointURL(url) {
    Debug.UrlHandler && console.log("UrlHandlerExt: setEntryPointUrl: " + url);
    this.entryPointURL = url.split("/");
    this.entryPointURL.shift(); // shift -> removes mac (BUT returns it also, so use a separate line :-)
};

UrlHandlerExtension.prototype.syncEntryPointURL = function syncEntryPointURL(url) {
    Debug.UrlHandler && console.info("UrlHandlerExtension syncEntryPointURL: " + this.lastActiveMiniserverLocation + ", ignoring: " + url);
    this.entryPointURL = this.lastActiveMiniserverLocation;
};

UrlHandlerExtension.prototype.getEntryPointURL = function getEntryPointURL() {
    Debug.UrlHandler && console.log("UrlHandlerExt: getEntryPointURL: " + JSON.stringify(this.entryPointURL));
    return this.entryPointURL;
};

UrlHandlerExtension.prototype.reloadWithCurrentUrl = function reloadWithCurrentUrl(miniserver) {
    Debug.UrlHandler && console.log("UrlHandlerExt: reloadWithCurrentUrl"); // TODO-thallth CompChannel in an extension?

    Debug.MSSession && console.log(this.name, "reloadWithCurrentUrl > StopMSSession first");
    CompChannel.emit(CCEvent.StopMSSession); // to force reconnection
    // prepare current location

    var loc = JSON.parse(PersistenceComponent.getLocalStorageItem("location")).location;
    loc = loc.slice(loc.indexOf('/') + 1); // create url start with miniserver

    var urlStart = UrlHelper.createURLStart({
        mac: miniserver.serialNo
    });
    urlStart += "&loc=" + encodeURIComponent(loc); // Launch URL Start

    UrlHelper.apply(urlStart);
};

UrlHandlerExtension.prototype.updateURL = function updateURL(url) {
    Debug.UrlHandler && console.log("UrlHandlerExt: updateUrl: " + url);
    window.CURRENT_URL = url;
    VendorHub.Usage.urlChanged(url);

    if (url) {
        try {
            UrlHelper.createUrlStartFromCurrentLocation(url);
            Debug.UrlHandler && console.log(" - store url " + url);
            url = url.toLowerCase();
            this.setLastURL(url);
            Debug.UrlHandler && console.log("UrlHandlerExt: updated location to: ", url);
            PersistenceComponent.setLocalStorageItem("location", JSON.stringify({
                location: url,
                date: Date.now()
            }));
        } catch (e) {//console.log(" - don't store url " + url);
        } // Adopt the URL to get rid of any path in front of the #
        // http://504F941014D6.dns.loxonecloud.com/webinterface_versions/20170426/#/504F94101436
        // http://504F941014D6.dns.loxonecloud.com/#/504F94101436
        // This allows the user to reload the CloudDNS Webinterface


        if (window.CLOUD_DNS_MS_ADDRESS) {
            url = "/#/" + url;
        } else {
            url = "#/" + url;
        }

        try {
            var platform = PlatformComponent.getPlatformInfoObj().platform;

            if (platform === PlatformType.Webinterface || platform === PlatformType.DeveloperInterface) {
                window.history.replaceState({}, "Loxone", url);
            }
        } catch (e) {
            console.error(e.stack); // after 100 replaceState, iOS crashes, a reload gives another 100! :-)
            //location.reload(); // no longer needed!
        } //location.replace("#" + url);
        //window.location.hash = "#" + url; // this triggers the popstate event, we don't want that here!

    }
};

UrlHandlerExtension.prototype.urlStart = function urlStart(url) {
    Debug.UrlHandler && console.log("UrlHandlerExt: urlStart: " + JSON.stringify(url)); // Why do we also need to check for "loxone:"?
    // The iOS 12 Shortcuts App opens the app with "loxone:" instead "loxone://"

    if (url === UrlHelper.LOXONE_SCHEME || url === UrlHelper.LOXONE_SCHEME.replace("//", "")) {
        return;
    } else if (url.hasPrefix(UrlHelper.LOXONE_CMD_SCHEME)) {
        SandboxComponent.handleUrlCommand(url).then(function () {
            Debug.UrlHandler && console.log(" handleUrlCommand succeeded!");
        }, function (err) {
            Debug.UrlHandler && console.error(" handleUrlCommand failed: ", err);
        });
        return;
    }

    NavigationComp.disableAnimationsTemp();

    try {
        // In order to specifically target the new app, "lxkerberos://" was introduced
        if (url.hasPrefix(UrlHelper.LOXONE_LX_KERBEROS_SCHEME)) {
            url = url.slice(UrlHelper.LOXONE_LX_KERBEROS_SCHEME.length); // cut off the 'lxkerberos://'
        } else {
            url = url.slice(UrlHelper.LOXONE_SCHEME.length); // cut off the 'loxone://'
        }

        // Windows is a bitch, it adds a slash after the "host" part and before the query!
        if (/^ms\/?\?/.test(url)) {
            // url start to a specific Miniserver
            return this._handleAppLocationURLStart(url);

            // Windows is a bitch, it adds a slash after the "host" part and before the query!
        } else if (/^\/?\?loc=/.test(url)) {
            // url start to last/no Miniserver (eg. simply to QuickActions)
            return this._handleAppLocationURLStart(url);
        } else {
            throw new Error("'" + url + "' is not a valid URL-Scheme!");
        }
    } catch (e) {
        console.log(e);

        this._showURLStartErrorPopup(e);
    }
};

UrlHandlerExtension.prototype.setLastURL = function setLastURL(url) {
    Debug.UrlHandler && console.log("UrlHandlerExt: setLastURL: " + url);
    this.lastActiveMiniserverLocation = url.split("/");
    this.lastActiveMiniserverLocation.shift(); // shift -> removes mac (BUT returns it also, so use a separate line :-)
};

UrlHandlerExtension.prototype.getLastURL = function getLastURL() {
    Debug.UrlHandler && console.log("UrlHandlerExt: getLastURL ->" + this.lastActiveMiniserverLocation);
    return this.lastActiveMiniserverLocation;
};

UrlHandlerExtension.prototype.resetLastURL = function resetLastURL() {
    Debug.UrlHandler && console.log("UrlHandlerExt: resetLastURL");
    this.lastActiveMiniserverLocation = null;
    this.entryPointURL = null;
};

UrlHandlerExtension.prototype._handleCachedUrlStart = function _handleCachedUrlStart() {
    var cachedUrlStart = window._cachedUrlStart;
    delete window._cachedUrlStart; // use a short timeout to be sure that everything is initialized properly

    setTimeout(function () {
        UrlHelper.apply(cachedUrlStart);
    }, 10);
};

UrlHandlerExtension.prototype._showURLStartErrorPopup = function _showURLStartErrorPopup(error) {
    var content = {
        title: _('url-start.failed'),
        message: error ? error : "",
        buttonOk: true,
        icon: Icon.ERROR,
        color: window.Styles.colors.red
    };
    NavigationComp.showPopup(content);
};

UrlHandlerExtension.prototype._handleAppLocationURLStart = function _handleAppLocationURLStart(location) {
    Debug.UrlHandler && console.log("UrlHandlerExt: _handleAppLocationURLStart: " + location);
    let weakThis = this;
    var params = getURIParameters(location),
        cleanedLocUrl = null;

    if (params.loc && params.loc !== "") {
        cleanedLocUrl = params.loc.split("/");
    }

    if (params.mac != null && params.mac !== "") {
        var ms = PersistenceComponent.getMiniserver(params.mac.toUpperCase());

        if (ms) {
            if (ActiveMSComponent.getActiveMiniserver() && ActiveMSComponent.getActiveMiniserver().serialNo === ms.serialNo) {
                Debug.UrlHandler && console.info("already connected to this Miniserver, only navigate");

                if (params.loc === UrlStartLocation.LAST_POSITION) {
                    var loc = this.getLastURL();

                    if (loc) {
                        params.loc = loc.join("/");
                    }
                }
            } else {
                if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
                    // We need to set the password because we deleted it before
                    this._readCredsFromParams(params, ms);
                }

                NavigationComp.connectTo(ms);
            }

            if (cleanedLocUrl) {
                return NavigationComp.handleURL(cleanedLocUrl);
            }
        } else {
            weakThis._showURLStartErrorPopup();
        }
    } else if (typeof params.host === "string") {
        Debug.UrlHandler && console.log("UrlHandlerExt: _handleAppLocationURLStart >> HOST: " + params.host);
        var miniserver = {};

        this._readCredsFromParams(params, miniserver);

        miniserver = prepareConnAddress(params.host, miniserver, ActiveMSComponent.getDataCenter());
        NavigationComp.connectTo(miniserver); // now connecting is in progress! (maybe credentials are requested!)

        Debug.UrlHandler && console.log("UrlHandlerExt:      >> connection establishing to ms started, loc=" +
            (cleanedLocUrl ? cleanedLocUrl.join("/") : "-none-"));
        cleanedLocUrl && NavigationComp.handleURL(cleanedLocUrl);

    } else {
        NavigationComp.connectToLastMiniserver();
        cleanedLocUrl && NavigationComp.handleURL(cleanedLocUrl);
    }
};
/**
 * Will read the credentials from the parameters object provided. It will store them to the ms object provided.
 * @param params    the params object
 * @param msObj     the ms for whom the credentials are for.
 * @private
 */


UrlHandlerExtension.prototype._readCredsFromParams = function _readCredsFromParams(params, msObj) {
    msObj.activeUser = params.usr;

    if (params.trustToken) {
        msObj.trustToken = VendorHub.Crypto.encrypt(params.trustToken);
    }
    if (params.pwd) {
        msObj.password = VendorHub.Crypto.encrypt(params.pwd);
    } else if (params.token) {
        msObj.token = VendorHub.Crypto.encrypt(params.token);
    }
};

UrlHandlerExtension.prototype._handleAppLocation = function _handleAppLocation(loc) {
    Debug.UrlHandler && console.log("UrlHandlerExt: _handleAppLocation: " + loc);

    if (loc != null && loc !== "") {
        var pathInfoParts = loc.split("/"); // make lowercase

        for (var i = 0; i < pathInfoParts.length; i++) {
            pathInfoParts[i] = pathInfoParts[i].toLowerCase();
        }

        this.lastActiveMiniserverLocation = pathInfoParts;
    }
};

UrlHandlerExtension.prototype._handleCommandURLStart = function _handleCommandURLStart(url) {
    Debug.UrlHandler && console.log("UrlHandlerExt: _handleCommandURLStart: " + url);
    url = url.slice(UrlHelper.LOXONE_CMD_SCHEME.length); // cut off the 'loxonecmd://'

    try {
        // Windows is a bitch, it adds a slash after the "host" part and before the query!
        if (/^ms\/?\?/.test(url)) {
            // extract Miniserver Info
            var params = getURIParameters(url),
                validVisuPassword,
                command = params.cmd,
                command2 = params.cmd2;

            var requestKey = function (host, retryCnt) {
                Debug.UrlHandler && console.log(" - requesting key from: " + host);
                var def = Q.defer(),
                    url = host + Commands.GET_KEY;
                $.ajax({
                    url: url,
                    type: 'GET',
                    timeout: retryCnt ? retryCnt * 1000 : 1000,
                    success: function (data, succText, responseObj) {
                        def.resolve(data.LL.value);
                    },
                    error: function (responseObj, errorText, errorMessage) {
                        if (retryCnt < 3) {
                            if (!retryCnt) {
                                retryCnt = 0;
                            } else {
                                retryCnt++;
                            }

                            def.resolve(requestKey(host, retryCnt));
                        } else {
                            def.reject();
                        }
                    }
                });
                return def.promise;
            };

            var goOn = function (miniserver, visuPassword) {
                var internal = miniserver.localUrl || "",
                    external = miniserver.remoteUrl || "",
                    host = internal || external || "";

                if (Regex.CLOUD_DNS.test(external)) {
                    var idx = external.lastIndexOf("/"),
                        mac = external.substr(idx + 1);
                    external = getCloudDnsHost(ActiveMSComponent.getDataCenter()) + "/?" + mac; // https://www.wrike.com/open.htm?id=73219296
                }

                if (ActiveMSComponent.getActiveMiniserver() && ActiveMSComponent.getActiveMiniserver().serialNo === miniserver.serialNo) {
                    Debug.UrlHandler && console.info("already connected to this Miniserver, only send command..");
                    host = ActiveMSComponent.getCurrentUrl();
                    Debug.UrlHandler && console.log("try current url");
                } else {
                    NavigationComp.connectTo(miniserver);
                }

                var sendingNotification = GUI.Notification.createGeneralNotification({
                    title: _('command.sending'),
                    subtitle: command
                }, NotificationType.INFO);

                var tryToSend = function (host, cmd, credentials, retryCnt, visuPassword) {
                    var def = Q.defer();

                    if (!host.hasPrefix("http://")) {
                        host = "http://" + host;
                    }

                    if (!host.hasSuffix("/")) {
                        host = host + "/";
                    }

                    var keys = [];
                    keys.push(requestKey(host));

                    if (typeof visuPassword === "string") {
                        keys.push(requestKey(host));
                    }

                    Q.all(keys).done(function (res) {
                        var key1 = res[0],
                            hashAlg = VendorHub.Crypto.getHashAlgorithmForMs(),
                            hash = VendorHub.Crypto["Hmac" + hashAlg](credentials, "utf8", key1, "hex", "hex"),
                            cmdToSend = cmd;

                        if (typeof visuPassword === "string") {
                            Debug.UrlHandler && console.log(" - adding the visu password..");
                            var key2 = res[1];
                            var visuPwdHash = VendorHub.Crypto["Hmac" + hashAlg](visuPassword, "utf8", key2, "hex", "hex"),
                                uuid = UrlHelper.getControlUUIDFromCommandString(cmd);
                            var cmdWithoutPrefix = UrlHelper.getCommandFromCommandString(cmd);
                            cmdToSend = Commands.format(Commands.CONTROL.SECURED_COMMAND, visuPwdHash, uuid, cmdWithoutPrefix);
                        }

                        var url = host + cmdToSend;
                        Debug.UrlHandler && console.log(" - sending to url: " + url); // adding creds

                        url = url + "?auth=" + hash;
                        $.ajax({
                            url: url,
                            type: 'GET',
                            timeout: retryCnt <= 1 ? 3 * 1000 : 10 * 1000,
                            success: function (data, succText, responseObj) {
                                try {
                                    var resCode = getLxResponseCode(data);
                                } catch (e) {
                                    console.error(e);
                                }

                                if (resCode === ResponseCode.OK) {
                                    Debug.UrlHandler && console.log(" - success!");

                                    if (typeof visuPassword === "string") {
                                        // storing the visu password for the 2nd command
                                        validVisuPassword = visuPassword;
                                    }

                                    def.resolve();
                                } else if (resCode === ResponseCode.SECURED_CMD_FAILED) {
                                    Debug.UrlHandler && console.info(" - requesting visu pwd!");
                                    SandboxComponent.acquireVisuPassword(true).done(function success(visuPass) {
                                        def.resolve(tryToSend(host, cmd, credentials, retryCnt++, visuPass));
                                    }, function error() {
                                        // bail out
                                        def.reject(true);
                                    });
                                } else {
                                    Debug.UrlHandler && console.warn(" - failed");
                                    def.reject();
                                }
                            },
                            error: function (responseObj, errorText, errorMessage) {
                                if (retryCnt === 0) {
                                    Debug.UrlHandler && console.log("failed, try internal"); // try second time

                                    def.resolve(tryToSend(internal, cmd, creds, 1));
                                } else if (retryCnt === 1) {
                                    Debug.UrlHandler && console.log("failed, try external"); // try second time

                                    def.resolve(tryToSend(external, cmd, creds, 2));
                                } else {
                                    def.reject();
                                }
                            }
                        });
                    }, function () {
                        // failed 3 times
                        if (retryCnt === 0) {
                            Debug.UrlHandler && console.log("failed, try internal"); // try second time

                            def.resolve(tryToSend(internal, cmd, creds, 1));
                        } else if (retryCnt === 1) {
                            Debug.UrlHandler && console.log("failed, try external"); // try second time

                            def.resolve(tryToSend(external, cmd, creds, 2));
                        } else {
                            def.reject();
                        }
                    });
                    return def.promise;
                };

                var usr = miniserver.activeUser,
                    pwd;

                try {
                    pwd = VendorHub.Crypto.decrypt(miniserver.password);

                    if (pwd.length === 0) {
                        pwd = miniserver.password;
                    }
                } catch (e) {
                    console.error(e);
                    pwd = miniserver.password;
                }

                var creds = usr + ":" + pwd; // try first time

                tryToSend(host, command, creds, 0, visuPassword).done(function () {
                    if (command2) {
                        tryToSend(host, command2, creds, 0, validVisuPassword ? validVisuPassword : null).done(function () {
                            sendingNotification.remove();
                            GUI.Notification.createGeneralNotification({
                                title: _('command.successful'),
                                //subtitle: url,
                                removeAfter: 5
                            }, NotificationType.SUCCESS);
                        }, function (preventFailedNotification) {
                            sendingNotification.remove();

                            if (preventFailedNotification) {
                                return;
                            }

                            GUI.Notification.createGeneralNotification({
                                title: _('unable-to-send-command'),
                                //subtitle: url,
                                removeAfter: 5
                            }, NotificationType.ERROR);
                        });
                    } else {
                        sendingNotification.remove();
                        GUI.Notification.createGeneralNotification({
                            title: _('command.successful'),
                            //subtitle: url,
                            removeAfter: 5
                        }, NotificationType.SUCCESS);
                    }
                }, function (preventFailedNotification) {
                    sendingNotification.remove();

                    if (preventFailedNotification) {
                        return;
                    }

                    GUI.Notification.createGeneralNotification({
                        title: _('unable-to-send-command'),
                        //subtitle: url,
                        removeAfter: 5
                    }, NotificationType.ERROR);
                });
            };

            if (params.mac != null && params.mac !== "") {
                var ms = PersistenceComponent.getMiniserver(params.mac);

                if (ms && command != null) {
                    var controlUUID = UrlHelper.getControlUUIDFromCommandString(command); // try to load the control, if it can't be loaded, it no longer exists!

                    PersistenceComponent.getControlOfMiniserver(params.mac, controlUUID).done(function (result) {
                        if (result === ControlLoadError.MINISERVER_NOT_AVAILABLE || result === ControlLoadError.CONTROL_NOT_AVAILABLE) {
                            this._showURLStartErrorPopup(_('error') + ": " + _('control.no-longer-exists'));
                        } else {
                            askAboutPermissionForCommand(result, ms, command, url).done(function () {
                                var subControlsObjs = result.details.controls;

                                if (!!subControlsObjs) {
                                    // Check if it is a groupControl
                                    var subCtrlPromises = [true];
                                    subControlsObjs.forEach(function (ctrlUuid, idx) {
                                        subCtrlPromises.push(PersistenceComponent.getControlOfMiniserver(params.mac, ctrlUuid));
                                    });
                                    Q.all(subCtrlPromises).then(function (subControls) {
                                        for (var i = 0; i < subControls.length; i++) {
                                            var subCtrl = subControls[i];
                                            result.isSecured = subCtrl.isSecured;

                                            if (result.isSecured) {
                                                break;
                                            }
                                        }
                                    });
                                }

                                if (result.isSecured) {
                                    console.log("UrlHandler: requesting visu password..");
                                    SandboxComponent.acquireVisuPassword().done(function success(result) {
                                        goOn(ms, result);
                                    }, function error() {// bail out
                                    });
                                } else {
                                    goOn(ms);
                                }
                            });
                        }
                    }.bind(this));
                } else {
                    this._showURLStartErrorPopup(_('error') + ": " + _('control.no-longer-exists'));
                }
            } else {
                var miniserver = {
                    activeUser: params.usr,
                    password: VendorHub.Crypto.encrypt(params.pwd)
                };
                miniserver = prepareConnAddress(params.host, miniserver, ActiveMSComponent.getDataCenter());
                goOn(miniserver);
            }
        } else {
            throw new Error("'" + url + "' is not a valid Command-URL-Scheme!");
        }
    } catch (e) {
        console.log(e);

        this._showURLStartErrorPopup(e);
    }
};

var askAboutPermissionForCommand = function askAboutPermissionForCommand(control, miniserver, command, url) {
    var def = Q.defer(),
        urlWithScheme = UrlHelper.LOXONE_CMD_SCHEME + url;
    BiometricHelper.unlockApp().done(function () {
        getUrlStartFile().done(function (urlStartFile) {
            var knownUrls = urlStartFile.knownUrls;

            if (knownUrls.indexOf(urlWithScheme) === -1) {
                // try to create a text for the command, otherwise display the command itself
                var currentMs = ActiveMSComponent.getActiveMiniserver(),
                    msName = currentMs && currentMs.serialNo === miniserver.serialNo ? "" : miniserver.msName + ":<br/>",
                    // if it's the current ms, don't add the name
                    groupDetail = control.groupDetail ? "<br/>(" + control.groupDetail + ")" : "",
                    text = command.replace("jdev/sps/io/", ""),
                    cmdParts = text.split("/");

                try {
                    addControlTypeToControl(control);
                    text = createCmdText(control, cmdParts);
                } catch (e) {
                    console.log(e.stack);
                }

                NavigationComp.showPopup({
                    title: _('command.execute.question'),
                    message: msName + text + groupDetail,
                    buttons: [{
                        title: _('execute'),
                        color: window.Styles.colors.orange
                    }, {
                        title: _('execute.always'),
                        color: window.Styles.colors.orange
                    }],
                    buttonCancel: true,
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.orange
                }).done(function (res) {
                    if (res === 1) {
                        knownUrls.push(urlWithScheme);
                        saveUrlStartFile(urlStartFile);
                    }

                    def.resolve();
                }.bind(this));
            } else {
                def.resolve();
            }
        });
    });
    return def.promise;
};

var getUrlStartFile = function () {
    return PersistenceComponent.loadFile(URL_START_FILENAME, DataType.OBJECT).then(null, function () {
        return {
            knownUrls: []
        };
    });
};

var saveUrlStartFile = function (file) {
    return PersistenceComponent.saveFile(URL_START_FILENAME, file, DataType.OBJECT);
};

export default UrlHandlerExtension;

export function handleOpenURL(url) {
    Debug.UrlHandler && console.info("caching url start due to the fact that nobody is listening to it right now");
    window._cachedUrlStart = url;
};

export const UrlHelper = {
    LOXONE_SCHEME: "loxone://",
    LOXONE_CMD_SCHEME: "loxonecmd://",
    LOXONE_LX_KERBEROS_SCHEME: "lxkerberos://",
    isLoxoneURL: function isLoxoneURL(url) {
        return url && (url.hasPrefix(this.LOXONE_SCHEME) || url.hasPrefix(this.LOXONE_CMD_SCHEME || url.hasPrefix(this.LOXONE_LX_KERBEROS_SCHEME)));
    },

    /**
     * creates an URL for the current Miniserver and location
     * @param miniserver
     * @param loc location
     * @returns {string}
     */
    createURLStart: function (miniserver, loc) {
        Debug.UrlHandler && console.log("UrlHandlerExt: createUrlStart: loc=" + JSON.stringify(loc));
        var url = this.LOXONE_SCHEME;
        url = url + "ms?" + this._createBaseURL(miniserver);

        if (loc) {
            url = url + "&loc=";

            if (typeof loc === "object") {
                if (loc.hasOwnProperty(UrlStartLocation.CONTROL) && this._testUUID(loc.control)) {
                    if (loc.alert) {
                        url = url + UrlStartLocation.CONTROL_ALERT;
                    } else {
                        url = url + UrlStartLocation.CONTROL;
                    }

                    url = url + "%2F" + loc.control;
                } else if (loc.hasOwnProperty(UrlStartLocation.ROOM) && this._testUUID(loc.room)) {
                    url = url + UrlStartLocation.ROOM + "%2F" + loc.room;
                } else if (loc.hasOwnProperty(UrlStartLocation.CATEGORY) && this._testUUID(loc.category)) {
                    url = url + UrlStartLocation.CATEGORY + "%2F" + loc.category;
                } else if (loc.hasOwnProperty(UrlStartLocation.MESSAGE_CENTER)) {
                    url = url + UrlStartLocation.MESSAGE_CENTER + "%2F" + loc[UrlStartLocation.MESSAGE_CENTER];
                } else if (loc.hasOwnProperty(UrlStartLocation.NOTIFICATIONS)) {
                    url = url + UrlStartLocation.NOTIFICATIONS + "%2F" + loc[UrlStartLocation.NOTIFICATIONS];
                } else if (loc.hasOwnProperty(UrlStartLocation.USER_MANAGEMENT)) {
                    url = url + UrlStartLocation.USER_MANAGEMENT + "%2F" + loc[UrlStartLocation.USER_MANAGEMENT];
                } else if (loc.hasOwnProperty(UrlStartLocation.DEVICE_SEARCH) && ActiveMSComponent.isValidDeviceSearchType(loc[UrlStartLocation.DEVICE_SEARCH])) {
                    url = url + UrlStartLocation.DEVICE_SEARCH + "%2F" + loc[UrlStartLocation.DEVICE_SEARCH];
                } else {
                    console.info("UrlHandlerExt: createUrlStart: --> not a valid url start location!");
                    throw new Error("location info invalid:" + JSON.stringify(loc));
                }
            } else if (typeof loc === "string") {
                loc = loc.toLowerCase();

                if (loc.indexOf("/") !== -1) {
                    var secondPart = loc.slice(loc.indexOf("/") + 1);

                    if (loc.indexOf(UrlStartLocation.CONTROL + "/") === 0 && this._testUUID(secondPart)) {
                        url = url + UrlStartLocation.CONTROL + "%2F" + secondPart;
                    } else if (loc.indexOf(UrlStartLocation.CONTROL_ALERT + "/") === 0 && this._testUUID(secondPart)) {
                        url = url + UrlStartLocation.CONTROL_ALERT + "%2F" + secondPart;
                    } else if (loc.indexOf(UrlStartLocation.ROOM + "/") === 0 && this._testUUID(secondPart)) {
                        url = url + UrlStartLocation.ROOM + "%2F" + secondPart;
                    } else if (loc.indexOf(UrlStartLocation.CATEGORY + "/") === 0 && this._testUUID(secondPart)) {
                        url = url + UrlStartLocation.CATEGORY + "%2F" + secondPart;
                    } else if (loc.indexOf(UrlStartLocation.BATTERY_MONITOR + "/") === 0 && this._testUUID(secondPart)) {
                        url = url + UrlStartLocation.BATTERY_MONITOR + "%2F" + secondPart;
                    } else if (loc.indexOf(UrlStartLocation.MESSAGE_CENTER + "/") === 0) {
                        url = url + UrlStartLocation.MESSAGE_CENTER; // append the optional entryUuid to directly navigate to allow direct navigation to an entry

                        if (secondPart) {
                            url += "%2F" + secondPart;
                        }
                    } else if (loc.indexOf(UrlStartLocation.AUTOMATIC_DESIGNER + "/") === 0) {
                        url = url + UrlStartLocation.AUTOMATIC_DESIGNER; // append the optional ruleId to directly navigate to allow direct navigation to an rule

                        if (this._testUUID(secondPart)) {
                            url += "%2F" + secondPart;
                        }
                    } else if (loc.indexOf(UrlStartLocation.AUTOMATIC_DESIGNER_SCENES + "/") === 0) {
                        url = url + UrlStartLocation.AUTOMATIC_DESIGNER_SCENES; // append the optional ruleId to directly navigate to allow direct navigation to a scene

                        if (this._testUUID(secondPart)) {
                            url += "%2F" + secondPart;
                        }
                    } else if (loc.indexOf(UrlStartLocation.NOTIFICATIONS + "/") === 0) {
                        url = url + UrlStartLocation.NOTIFICATIONS; // append the optional notification type to directly navigate to the desired notification screen

                        if (secondPart) {
                            url += "%2F" + secondPart;
                        }
                    } else if (loc.indexOf(UrlStartLocation.USER_MANAGEMENT + "/") === 0) {
                        url = url + UrlStartLocation.USER_MANAGEMENT; // append the optional userUuid to directly open the desired user

                        if (secondPart) {
                            url += "%2F" + secondPart;
                        }
                    } else if (loc.indexOf(UrlStartLocation.DEVICE_SEARCH + "/") === 0 && ActiveMSComponent.isValidDeviceSearchType(secondPart)) {
                        url = url + UrlStartLocation.DEVICE_SEARCH; // append the optional userUuid to directly open the desired user
                        if (secondPart) {
                            url += "%2F" + secondPart;
                        }
                    } else {
                        throw new Error("current location not valid for url start:" + loc);
                    }
                } else {
                    if (loc === UrlStartLocation.LAST_POSITION) {
                        url = url + UrlStartLocation.LAST_POSITION;
                    } else if (loc === UrlStartLocation.CENTRAL) {
                        url = url + UrlStartLocation.CENTRAL;
                    } else if (loc === UrlStartLocation.AMBIENT_MODE) {
                        url = url + UrlStartLocation.AMBIENT_MODE;
                    } else if (loc === UrlStartLocation.ROOM) {
                        url = url + UrlStartLocation.ROOM;
                    } else if (loc === UrlStartLocation.CATEGORY) {
                        url = url + UrlStartLocation.CATEGORY;
                    } else if (loc === UrlStartLocation.WEATHER) {
                        url = url + UrlStartLocation.WEATHER;
                    } else if (loc === UrlStartLocation.FAVORITES) {
                        url = url + UrlStartLocation.FAVORITES;
                    } else if (loc === UrlStartLocation.TASK_RECORDER) {
                        url = url + UrlStartLocation.TASK_RECORDER;
                    } else if (loc === UrlStartLocation.AUTO_PILOT) {
                        url = url + UrlStartLocation.AUTO_PILOT;
                    } else if (loc === UrlStartLocation.AUTOMATIC_DESIGNER) {
                        url = url + UrlStartLocation.AUTOMATIC_DESIGNER;
                    } else if (loc === UrlStartLocation.AUTOMATIC_DESIGNER_SCENES) {
                        url = url + UrlStartLocation.AUTOMATIC_DESIGNER_SCENES;
                    } else if (loc === UrlStartLocation.QUICK_ACTIONS) {
                        url = url + UrlStartLocation.QUICK_ACTIONS;
                    } else if (loc === UrlStartLocation.BATTERY_MONITOR) {
                        url = url + UrlStartLocation.BATTERY_MONITOR;
                    } else if (loc === UrlStartLocation.PRESENCE_DETECTOR) {
                        url = url + UrlStartLocation.PRESENCE_DETECTOR;
                    } else if (loc === UrlStartLocation.MESSAGE_CENTER) {
                        url = url + UrlStartLocation.MESSAGE_CENTER;
                    } else if (loc === UrlStartLocation.NOTIFICATIONS) {
                        url = url + UrlStartLocation.NOTIFICATIONS;
                    } else if (loc === UrlStartLocation.USER_MANAGEMENT) {
                        url = url + UrlStartLocation.USER_MANAGEMENT;
                    } else if (loc === UrlStartLocation.DEVICE_SEARCH) {
                        console.info("UrlHandlerExt: createUrlStart: cannot create url start when searchType isn't known!");
                        throw new Error("location info invalid:" + JSON.stringify(loc));
                    } else {
                        console.log("UrlHandlerExt", "current screen not valid for URL start: " + loc);
                        throw new Error("current screen not valid for url start:" + loc);
                    }
                }
            } else {
                throw new Error("location info invalid:" + loc);
            }
        }

        return url;
    },

    /**
     * Check if a given location urlScheme is valid and the app knows what to do with it.
     * false will be returned, if the given location can't be parsed (malformed), is no loxone urlScheme,
     * or if the app can't handle the location defined in the urlScheme
     * @param location
     * @return {*}
     */
    validateLocationUrlStart: function validateLocationUrlStart(location) {
        var isValid = false,
            params = getURIParameters(location),
            loc;

        if (this.isLoxoneURL(location)) {
            if (params && params.loc) {
                // locations are defined in lower case
                loc = params.loc.toLowerCase(); // Also handle location parameters like uuids, just check if the primary location is valid!

                if (loc.indexOf("/") !== -1) {
                    loc = loc.split("/")[0];
                } // Finally check if the given location url is available and can be handled by the app


                isValid = Object.values(UrlStartLocation).indexOf(loc) >= 0;
            }
        }

        return isValid;
    },
    createUrlStartFromCurrentLocation: function (customLoc) {
        customLoc = customLoc || NavigationComp.getURL(); //location.hash.substr(2) // eg. #/EEE0009800B5/Menu -> location.hash is deprecated due to bugs!

        var mac = customLoc.substr(0, 12).toUpperCase(),
            loc = customLoc.substr(13);

        if (!Regex.MAC_ADDRESS.test(mac)) {
            throw new Error("not connected to a miniserver");
        }

        if (!loc || loc === "~" || loc.length === 0) {
            throw new Error("location info invalid:" + loc);
        }

        return this.createURLStart({
            mac: mac
        }, loc);
    },
    createCommandURLStart: function (miniserver, command) {
        var url = this.LOXONE_CMD_SCHEME;
        url = url + "ms?" + this._createBaseURL(miniserver);
        url = url + "&cmd=" + encodeURIComponent(command);
        return url;
    },
    apply: function () {
        // use a wrapper function, the handleOpenURL method may not be applied yet (on android)
        return window.handleOpenURL.apply(window, arguments);
    },
    getControlUUIDFromCommandString: function (command) {
        if (command.hasPrefix("jdev/sps/io/")) {
            command = command.slice(12);
            return command.slice(0, command.indexOf("/"));
        }

        return "";
    },
    getCommandFromCommandString: function (command) {
        if (command.hasPrefix("jdev/sps/io/")) {
            command = command.slice(12);
            return command.slice(command.indexOf("/") + 1);
        }

        return "";
    },
    getLocationInfoFromLocationString: function (loc) {
        if (loc) {
            if (loc.hasPrefix(UrlStartLocation.CONTROL + "/")) {
                return {
                    control: loc.substr(8)
                };
            } else if (loc.hasPrefix(UrlStartLocation.ROOM + "/")) {
                return {
                    room: loc.substr(5)
                };
            } else if (loc.hasPrefix(UrlStartLocation.CATEGORY + "/")) {
                return {
                    category: loc.substr(9)
                };
            }

            return loc;
        }

        return "";
    },
    _createBaseURL: function _createBaseURL(miniserver) {
        if (miniserver) {
            var url = "";

            if (miniserver.hasOwnProperty("mac")) {
                url = url + "mac=" + encodeURIComponent(miniserver.mac).toUpperCase();
            } else if (miniserver.hasOwnProperty("host")) {
                url = url + "host=" + encodeURIComponent(miniserver.host);

                if (miniserver.hasOwnProperty("usr")) {
                    url = url + "&usr=" + encodeURIComponent(miniserver.usr);
                }

                if (miniserver.hasOwnProperty("pwd")) {
                    url = url + "&pwd=" + encodeURIComponent(miniserver.pwd);
                }
            }

            return url;
        } else {
            throw new Error("can't create an URLStart without Miniserver information");
        }
    },
    _testUUID: function _testUUID(uuid) {
        if (Regex.UUID.test(uuid)) {
            return true;
        } else {
            throw new Error(uuid + " is not a valid UUID");
        }
    }
};
